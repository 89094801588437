<template>
  <div class="content" element-loading-text="拼命解锁文章中..." v-loading.fullscreen.lock="loading">
    <div class="title">CS 超级会员文章查看</div>
    <div class="end-time">到期日期:{{ vipTimetimePeriod }}</div>
    <div>
      <el-form
        label-position="top"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="卡密" prop="card_num">
          <el-input placeholder="请输入卡密"  v-model.trim="ruleForm.card_num"></el-input>
        </el-form-item>
        <el-form-item label="" prop="url">
          <div>
           <span style="color:red;">*</span> 链接 <span style="color:red;font-size:13px;">(如果文章解锁失败，请重试！此资源仅供学习)</span>
          </div>
          <el-input @change="urlChange" placeholder="在此输入cs 专栏博客链接,如：blog.csd*.nex、wenku.csd*.nex" v-model.trim="ruleForm.url"></el-input>
        </el-form-item>
       
        <el-form-item>
          <div class="action-btn">
            <el-button type="warning" @click="submitForm(false)">在线阅读</el-button>
            <el-button type="primary" @click="submitForm(true)">HTML 下载</el-button>
          </div>

          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
        <p v-if="downUrl">提示：如果下载或者在线预览被浏览器拦截，可复制下面链接在浏览地址栏中打开下载。</p>
        <el-form-item v-if="downUrl"  title="可复制在浏览器地址栏中打开下载">
          <el-input placeholder="请输入内容" v-model="downUrl">
          <template slot="prepend">HTML 下载地址</template>
          <template slot="append">
            <span class="copy-btn" @click="copyText(downUrl)">复制</span>
          </template>
        </el-input>
          <!-- <el-input placeholder="可复制在浏览器地址栏中打开下载" v-model="downUrl"></el-input> -->
        </el-form-item>
        <el-form-item v-if="downUrl"  title="可复制在浏览器地址栏中打开下载">
          <el-input placeholder="请输入内容" v-model="viewUrl">
          <template slot="prepend">在线阅读地址</template>
          <template slot="append">
            <span class="copy-btn" @click="copyText(viewUrl)">复制</span>
          </template>
        </el-input>
          <!-- <el-input placeholder="可复制在浏览器地址栏中打开下载" v-model="downUrl"></el-input> -->
        </el-form-item>
        <div class="img">
            <!-- <p>提示：这里是解锁文章页面下载。</p> -->
            <p>✔️VIP文章可查阅</p>
<p>✔️超级会员专栏可查阅</p>
<p>🚫付费专栏不可查阅</p>
       <div>
            <img src="@/static/img/jie_suo.png" alt="">
        </div>
        </div>

        <!-- <el-form-item v-if="downUrl"  title="可复制在浏览器地址栏中打开下载">
          <el-input placeholder="可复制在浏览器地址栏中打开下载" v-model="downUrl"></el-input>
        </el-form-item>
        <p>提示：如果被拦截，点击...后保存。如果文件大，等待的时间就长点</p> -->
      </el-form>
      <!-- <iframe  id="my-iframe" width="500px" height="400px" src="http://47.109.69.224:7019/view/Unity之2DUI和3DUI混合开发_unity3dui-CSDN博客.html" frameborder="0"></iframe> -->
    </div>
  </div>
</template>
  
  <script>
import axios from 'axios';

export default {
  name: 'ArticleDownload',
  data() {
    return {
      loading: false,
      ruleForm: {
        url: '',
        card_num: '',
        adapter: 1,
        wrap: true
      },
      downUrl: '',
      viewUrl: '',
      rules: {
        url: [{ required: true, message: '请输入链接', trigger: 'blur' }],
        card_num: [{ required: true, message: '请输入卡密', trigger: 'blur' }]
      },
      vipTimetimePeriod:''
    };
  },

  methods: {
    urlChange(url){
      this.ruleForm.url=this.ruleForm.url.split('?')[0]
    },
    submitForm(isDownLoad=true) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 调用接口
          if(this.ruleForm.url.includes('download.csdn.net/download')){
            this.$message({
                  message: '你的链接填写错误,你填写的是下载资源文件链接，需要下载积分资源文件请到该店铺淘宝中拍【单次版(不包更新)】，需要付费资源文件请拍【其他】',
                  type: 'error'
                });
            return
          }
          if(!this.ruleForm.url.includes('blog.csdn.net/')&&!this.ruleForm.url.includes('wenku.csdn.net/')&&!this.ruleForm.url.includes('download.csdn.net/blog')){
            this.$message({
                  message: '你的链接填写错误,请填写cs博客链接地址哦!',
                  type: 'error'
                });

            return

          }
          // if(!this.ruleForm.url.includes('wenku.csdn.net/')){
          //   this.$message({
          //         message: '你的链接填写错误,请填写cs博客链接地址哦!',
          //         type: 'error'
          //       });

          //   return

          // }
          // if(!this.ruleForm.url.includes('download.csdn.net/blog')){
          //   this.$message({
          //         message: '你的链接填写错误,请填写cs博客链接地址哦!',
          //         type: 'error'
          //       });

          //   return

          // }
      
          this.$message({
            message:`${isDownLoad?'开始下载中':'正在加载文件中'}...,请耐心等待，文件比较大就会久一点`,
            type: 'success'
          });
          this.loading = true;
          axios
            .post('https://www.totm.top/cs/jie_suo',{ ...this.ruleForm,url:this.ruleForm.url.split('?')[0] })
            .then(res => {
              console.log('响应参数', res);
              this.getTimeVip()
            
                // 不管成功还是失败都缓存
                localStorage.setItem('url',this.ruleForm.url)
              if (res.data.code == 200) {
                this.$message({
                  message: isDownLoad?'下载成功，请注意查收':'加载成功，请注意查看',
                  type: 'success'
                });

                let str = res.data.data;
                let lastIndex = str.lastIndexOf("/");
                let lastSegment = str.substring(lastIndex + 1);
                var beforeSlash = str.substring(0, lastIndex);

                // this.downUrl = 'http://47.109.69.224:7019'+res.data.data;
                // this.downUrl = 'https://www.totm.top/cs'+res.data.data;
                this.downUrl = 'https://www.totm.top/cs'+beforeSlash+'/'+encodeURIComponent(lastSegment);
                this.viewUrl=this.downUrl.replace('download','view')
                // this.downUrl = res.data.data;
                localStorage.setItem('url',this.ruleForm.url)
                localStorage.setItem('code',this.ruleForm.card_num)

                console.log("下载地址",this.downUrl);
                
                if(isDownLoad){
                  console.log("执行");
                  
                  let a = document.createElement('a'); //创建一个a标签元素
                a.style.display = 'none'; //设置元素不可见
                a.href = this.downUrl; //设置下载地址，http地址
                document.body.appendChild(a); //追加dom元素
                a.click(); //触发点击,下载
                document.body.removeChild(a); //删除dom元素


                }else{
                  window.open(this.downUrl.replace('download','view'), '_blank');
                }
             
              }else {
                if(res.data.msg.includes("latin-1") ){
                  this.$message({
                  message: '请正确填写cs 博客链接，链接格式为http开头，如果链接中有‘？’，链接中‘？’之前不能有中文和特殊字符',
                  type: 'error'
                });
                }else{
                  this.$message({
                  message: res.data.msg,
                  type: 'error'
                });
                }
              }
             
              this.loading = false;
            })
            .catch((error) => {
              // console.log("错误的响应",error);
              // console.log(123456,error.response)
              // console.log("错误的响应11",JSON.stringify(error));
              
              this.$message({
                message: '解锁失败，请联系客服人员解决问题',
                type: 'error'
              });
              this.loading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    copyText(text) {
      this.$copyText(text).then(() => {
        this.$message.success('复制成功');
      }).catch(() => {
        this.$message.error('复制失败');
      });
    },
    // replaceImages() {
    //   const iframe = document.getElementById('my-iframe');
    //   const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    //   const images = iframeDoc.getElementsByTagName('img');
 
    //   for (let i = 0; i < images.length; i++) {
    //     const oldSrc = images[i].src;
    //     console.log("旧地址",oldSrc);
        
    //     const newSrc = oldSrc.replace('https://img-blog.csdnimg.cn', 'http://localhost:8080/replay_Img');
    //     images[i].src = newSrc;
    //   }
    // }
    // handleOpenDrawer() {
    //   this.$refs.refImageDrawer.openUrl('./img.jpeg');
    // },

    // handleSave(base64, file) {
    //   console.log(base64);
    //   console.log(file);
    // }

    getTimeVip(){
//  得到会员日期
if(this.ruleForm.card_num){
  axios
            .get('https://www.totm.top/cs/userinfo/'+this.ruleForm.card_num )
            .then(res => {
              if (res.data.code == 200) {
                this.vipTimetimePeriod=res.data.endtime
              }else{
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                });

              }
             
              

          
            })
            .catch(() => {
              this.$message({
                message: '加载会员时间失败',
                type: 'error'
              });
       
            });
}
    }
  },
  mounted() {
    const urlParams = window.location.search.substring(1).split('&');
    const paramsObject = urlParams.reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = decodeURIComponent(value);
      return params;
    }, {});
    console.log(paramsObject);
    if (paramsObject && paramsObject.code) {
      this.ruleForm.card_num = paramsObject.code;
    }else{
      let code= localStorage.getItem('code')
      this.ruleForm.card_num=code
    }
    let url= localStorage.getItem('url')
 if(url){
    this.ruleForm.url=url

 }

this.getTimeVip()

       

  }

 
};
</script>
  
  <style lang="less">
.content {
  width: 768px;
  margin: 22px auto;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 30px;
  .title {
    text-align: center;
    font-size: 25px;
  }
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img{
    width: 100%;
    margin-bottom: 10px;
    img{
        width: 100%;
    }
}
.copy-btn{
  cursor: pointer;
}

.end-time{
  text-align: center;
  color: red;
  font-size: 12px;
  margin-top: 8px;
}

@media (max-width: 800px) {
  .content {
    width: calc(100% - 66px);
    /* margin: auto; */
  }
}
</style>
  